/** @jsx jsx */
import { jsx, Box, Text } from 'theme-ui'
import Layout from '../components/layout'
import Hero from '../components/hero'
import Wrapper from '../components/layout/wrapper'
import Inner from '../components/layout/inner'
import PartnerList from '../components/partners'
import { graphql } from 'gatsby'
import Promo from '../components/promo'
import Article, { Byline } from '../components/article'
import MetaData from '../components/metadata'

const Partners = props => {
  const page = props.data?.contentfulPartnerPage

  return (
    <Layout {...props} light={page?.theme === 'light'}>
      <MetaData title="Partners" />
      {page.hero && (
        <Hero data={page.hero} sx={{ marginBottom: 8 }} variant={page?.theme} />
      )}
      <Box sx={{ backgroundColor: 'white' }}>
        <Wrapper>
          <Inner>
            <Article {...page.article} />
            <PartnerList partners={page.partners} />
          </Inner>
        </Wrapper>
      </Box>
      {page?.promo && (
        <Wrapper>
          <Inner>
            <Promo data={page?.promo} dark={!props.light} />
          </Inner>
        </Wrapper>
      )}
    </Layout>
  )
}

export default Partners

export const pageQuery = graphql`
  query PartnerPage {
    contentfulPartnerPage {
      title
      theme
      hero {
        ...HeroFragment
      }
      promo {
        ...PromoFragment
      }
      article {
        title
        main {
          raw
          references {
            ...ArticleFragment
          }
        }
        aside {
          raw
          references {
            ...ArticleFragment
          }
        }
      }
      partners {
        title
        tier
        slug
        excerpt
        logo {
          file {
            url
          }
        }
      }
    }
  }
`
