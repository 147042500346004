/** @jsx jsx */
import { jsx, Grid } from 'theme-ui';
import { PartnerCard } from '../card'
import { useStaticQuery, graphql } from 'gatsby'

const List = (props) => {

  const allPartners = useStaticQuery(graphql`
    query AllPartners {
      allContentfulPartner(
        sort: {fields: tier}
      ) {
        edges {
          node {
            title
            tier
            slug
            excerpt
            logo {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const data = 
    props.partners
      ? props.partners
      : allPartners.allContentfulPartner?.edges.map(x => x.node);

  return (
    <Grid columns={[1,null,null,'repeat(2, 1fr)',null,null,'repeat(4, 1fr)']} py={8}>
      {data?.map((x,i) => {
        return (
          <PartnerCard 
            key={i}
            title={x.title}
            content={x.excerpt}
            image={x.logo?.file?.url}
            button={{label: 'Read More', url: `/partners/${x.slug}`}}
            variant={x.tier === 'lower' ? 'mid' : false}
          />
        )
      })}
    </Grid>
  )
}

export default List